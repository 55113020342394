export default [
  {path: '/kid/goods', name: 'goods', component: () => import('../views/goods/Goods.vue'),
      children: [
        {path: '',name: 'goods-list', component: () => import('../views/goods/GoodsList.vue')},
        {path: '/goods/:id',name: 'goods-view', component: () => import('../views/goods/GoodsForm.vue')},
        {path: '/partners/goods/:id',name: 'partners-goods-view', component: () => import('../views/goods/GoodsForm.vue')},
      ]
  },
  {path: '/kid/goods', name: 'goodsForm', component: () => import('../views/goods/Goods.vue'),
      children: [
        {path: '/kid/goods/form', name: 'goods-form', component: () => import('../views/goods/GoodsFormInsert.vue')},
        {path: '/kid/partners/goods/form', name: 'partners-goods-form', component: () => import('../views/goods/GoodsForm.vue')},
        {path: '/kid/update/:id',name: 'goods-update', component: () => import('../views/goods/GoodsForm.vue')},
      ]
  },
]