export default [
    {
        path: "/kid/marketing/coupon/history",
        name: "marketing-coupon-history",
        component: () => import("../views/marketing/coupon/CouponHistory.vue"),
    },
    {
        path: "/kid/marketing/coupon/manage",
        name: "marketing-coupon-manage",
        component: () => import("../views/marketing/coupon/CouponManage.vue"),
    },
    {
        path: "/kid/marketing/point/manage",
        name: "marketing-point-manage",
        component: () => import("../views/marketing/point/PointManage.vue"),
    },
    {
        path: "/kid/marketing/recommander/history",
        name: "marketing-recommander-history",
        component: () =>
            import("../views/marketing/recommander/RecommanderHistory.vue"),
    },
    {
        path: "/kid/marketing/recommander/code",
        name: "marketing-recommander-code",
        component: () =>
            import("../views/marketing/recommander/RecommenderCode.vue"),
    },
    {
        path: "/kid/marketing/membership",
        name: "marketing-membership",
        component: () =>
            import("../views/marketing/membership/Membership.vue"),
    },
    {
        path: "/kid/marketing/membership/addBenefit/:id",
        name: "marketing-membership-Benefit-id",
        component: () =>
            import("../views/marketing/membership/AddBenefit.vue"),
    },
    {
        path: "/kid/marketing/membership/addBenefit",
        name: "marketing-membership-Benefit",
        component: () =>
            import("../views/marketing/membership/AddBenefit.vue"),
    },
    {
        path: "/kid/marketing/recommander/create-code",
        name: "marketing-recommander-create-code",
        component: () =>
            import("../views/marketing/recommander/CreateCode.vue"),
    },
    {
        path: '/kid/marketing/recommander/create-code/:id',
        name: 'marketing-recommander-update-code', 
        component: () => 
            import("../views/marketing/recommander/CreateCode.vue"),
    },
];
