<template>
  <div class="align-between">
    <div class="title" v-html="state.navInfo.title"></div>
    <div>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-html="state.navInfo.navName"></el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
  <el-divider></el-divider>
</template>

<script>
import { reactive, watch, onMounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const store = proxy.$store;
    const route = useRoute();

    let navList = [];
    const state = reactive({
      navInfo: {
        title: "",
        navName: "",
      },
    });

    onMounted(() => {
      fnc.setNavigation();
    });

    watch(route, (value) => {
      fnc.setNavigation();
    });

    const fnc = {
      setNavigation() {
        const menu = this.getNavigation(
          { child: store.state.menuList },
          "path",
          route.path
        );
        state.navInfo.title =
          menu == null
            ? store.state.navInfo.title
            : navList.slice(menu.lev - 1, menu.lev).toString();
        state.navInfo.navName =
          menu == null
            ? store.state.navInfo.navName
            : navList.slice(0, menu.lev).join(" / ");

        if (menu != null) {
          store.commit("setNavInfo", state.navInfo);
        }
      },

      getNavigation(node, nodeKey, nodeValue) {
        if (node.href && node.href[nodeKey] == nodeValue) {
          return node;
        } else if (node.child != null) {
          let result = null;
          for (let i = 0; result == null && i < node.child.length; i++) {
            navList.splice(node.child[i].lev - 1, 1, node.child[i].title);
            result = this.getNavigation(node.child[i], nodeKey, nodeValue);
          }
          return result;
        }
        return null;
      },
    };

    return {
      state,
      fnc,
    };
  },
};
</script>
