export default [


    { path: '/kid/store/faq', name: 'store-faq', component: () => import('../views/store/StoreFAQ.vue') },
    { path: '/kid/store/faqForm/:id', name: 'store-faq-view', component: () => import('../views/store/StoreFAQForm.vue') },

    { path: '/kid/store/directQuestion', name: 'store-direct-question', component: () => import('../views/store/StoreDirectQuestion.vue') },
    { path: '/kid/store/directQuestionForm', name: 'store-direct-questionForm', component: () => import('../views/store/StoreDirectQuestionForm.vue') },
    { path: '/kid/store/directQuestionView/:id', name: 'store-direct-questionView', component: () => import('../views/store/StoreDirectQuestionForm.vue') },

    { path: '/kid/store/notice', name: 'store-notice', component: () => import('../views/store/StoreNotice.vue') },
    { path: '/kid/store/noticeForm/:id/:flag', name: 'store-notice-view', component: () => import('../views/store/StoreNoticeForm.vue') },

    { path: '/kid/store/goodsInquiry', name: 'store-goodsInquiry', component: () => import('../views/store/GoodsInquiry.vue') },
    { path: '/kid/store/goodsInquiryView/:id', name: 'store-goodsInquiry-view', component: () => import('../views/store/GoodsInquiryForm.vue') },

    { path: '/kid/store/review', name: 'store-review', component: () => import('../views/store/Review.vue') },

]