
<template>

  <el-container>
    <el-header>
        <el-row>
        <el-col :span="8">
           <div class="header-title">관리자 시스템</div>
        </el-col>
        <el-col :span="8">
        
        </el-col>
        <el-col :span="8">
            
            <div class="login"> 
                <div>
                    <!-- <a @click="fnc.logOut()"><i class="fas fa-sign-out-alt"></i>로그아웃</a> -->
                    <span style="margin-right:14px">
                        <span>{{state.loginMin}}</span> : <span>{{ state.loginSec}}</span>
                    </span>
                    <a @click="fnc.resetLoginTime">로그인연장</a> | 
                    <a @click="fnc.logOut()">로그아웃</a>
                </div>
            </div>
        </el-col>
    </el-row>

    </el-header>

      <el-main>         
           <sidebar-menu
                width="280px"
                :theme="state.theme"
                :menu="state.menu"
                :collapsed="state.collapsed"
                @update:collapsed="fnc.onToggleCollapse"
                @item-click="fnc.onItemClick"
                :show-one-child="true"
            />
            <div id="contents" :class="{'collapsed' : state.collapsed}">
                <slot />
            </div>
	  </el-main>
      

  </el-container>

  
</template>

<script>
import { reactive, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

export default {

    components: {
        SidebarMenu,
    },

     setup() {

        const { proxy } = getCurrentInstance();
        const store = proxy.$store;
		const router = proxy.$router;
		const axios = proxy.$axios;
        const messageBox = proxy.$messageBox;

        let logoutTimer = null;
        let loginExprTime =  0;

        const state = reactive({
            theme: 'white-theme',
            menu: store.state.menuList,
            collapsed: store.state.onToggleCollapse.collapsed,
            loginMin: "00",
            loginSec: "00", 
        })

        onMounted(() => {
            //onResize();
            // window.addEventListener('resize', onResize)
            logoutTimer = setInterval(fnc.keepLogin, 1000); 
            loginExprTime = store.state.remainLoginTime == 0 ? store.state.loginExprTime : store.state.remainLoginTime;
            
        });

        onUnmounted(() => {
            clearInterval(logoutTimer);
        })



        const onResize = () => {state.collapsed = window.innerWidth <= 767 ? true : false}

        const fnc = {
            logOut() {
                router.push({"path" : "/kid/login"})
                store.commit('logOut')
                store.commit('setMenuList', [])
                store.commit("setRemainLoginTime", 0);
                axios.defaults.headers.common['Authorization'] = "";
            },

            onToggleCollapse(collapsed) {
                state.collapsed = collapsed;
                store.commit('setToggleCollapse', collapsed);
            },

            onItemClick(event, item) {
                store.commit('setMenuId', item.id);
            },
            keepLogin() {
                let min = loginExprTime / 60; 
                min = Math.floor(min);
                
                let sec = loginExprTime - (60 * min);
                // console.log(loginExprTime)
                // console.log(min)
                // console.log(sec)
                state.loginMin = fnc.lpad(min, 2, "0");
                state.loginSec = fnc.lpad(sec, 2, "0");

                if(loginExprTime == 60 * 5) {
                    messageBox.confirm(
                        "로그인 시간이 5분남았습니다. 연장하시겠습니까?", { type: 'warning', confirmButtonText: '확인', cancelButtonText: '취소' }
                    ).then(() => fnc.resetLoginTime()).catch(() => {})
                }
                
                if(loginExprTime == 0){
                    messageBox.close();
                    clearInterval(logoutTimer) // num 이 0초가 되었을대 clearInterval로 타이머 종료
                    messageBox.alert("로그아웃되었습니다.",	{confirmButtonText: '확인', callback: action => {fnc.logOut()}});
                }
                store.commit("setRemainLoginTime", loginExprTime);
                loginExprTime--;
            },
            resetLoginTime() {
                loginExprTime = store.state.loginExprTime;
            },
            lpad(str , padLen , padStr){
                if (padStr.length > padLen) return str;

                str += ""; 
                padStr += ""; 
                while (str.length < padLen)
                    str = padStr + str;
                str = str.length >= padLen ? str.substring(0, padLen) : str;
                return str;
            },

        }

         return {
            state, fnc
        }
    },

}
</script>

<style>



</style>
