export default [

    // 회원관리 > 제휴회원관리
    {
        path: '/kid/btob/member', name: 'btob-member-list', component: () => import('../views/bTob/member/MemberList'),
    },

    // 회원관리 > 회원승인관리
    {
        path: '/kid/btob/memberApproval', name: 'btob-member-approvalManager', component: () => import('../views/bTob/member/MemberApproval'),
    },

    // 회원관리 > 회원해지관리
    {
        path: '/kid/btob/memberCancel', name: 'btob-member-cancelManager', component: () => import('../views/bTob/member/MemberCancel'),
    },

    // 정산 > 혜택정산
    {
        path: '/kid/btob/benefitSettlement', name: 'btob-settlement-benefitSettlement', component: () => import('../views/bTob/settlement/BenefitSettlement'),
    },

]