export default [
    {path: '/kid/storeManage/store',name: 'store-store', component: () => import('../views/storeManage/Store.vue')},
    {path: '/kid/storeManage/storeInfo',name: 'store-store-info', component: () => import('../views/storeManage/StoreInfo.vue')},
    
    {path: '/kid/storeManage/storeBannerSub/:id',name: 'store-banner-sub', component: () => import('../views/storeManage/storeBannerSub.vue')},
    {path: '/kid/storeManage/storeBannerSubForm/:button/:id',name: 'store-banner-sub-form', component: () => import('../views/storeManage/storeBannerSubForm.vue')},
    {path: '/kid/storeManage/storeBannerSubView/:button/:id/:bannerId',name: 'store-banner-sub-view', component: () => import('../views/storeManage/storeBannerSubForm.vue')},


    {path: '/kid/storeManage/storeMyInfo',name: 'store-store-myInfo', component: () => import('../views/storeManage/StoreMyInfo.vue')},
    {path: '/kid/storeManage/storeMyInfoForm',name: 'store-store-myInfoForm', component: () => import('../views/storeManage/StoreMyInfoForm.vue')},


    {path: '/kid/storeManage/managerList',name: 'manager-manager-list', component: () => import('../views/storeManage/ManagerList.vue')},
]