export default [
    { path: '/kid/statistics/member/member', name: 'statistics-member-member', component: () => import('../views/statistics/member/member.vue') },

    { path: '/kid/statistics/sales/summary', name: 'statistics-sales-summary', component: () => import('../views/statistics/sales/summary.vue') },
    { path: '/kid/statistics/sales/payment', name: 'statistics-sales-payment', component: () => import('../views/statistics/sales/payment.vue') },
    { path: '/kid/statistics/sales/professional', name: 'statistics-sales-professional', component: () => import('../views/statistics/sales/professional.vue') },
    { path: '/kid/statistics/sales/order', name: 'statistics-sales-order', component: () => import('../views/statistics/sales/order.vue') },
    { path: '/kid/statistics/sales/coupon', name: 'statistics-sales-coupon', component: () => import('../views/statistics/sales/coupon.vue') },
    { path: '/kid/statistics/sales/point', name: 'statistics-sales-point', component: () => import('../views/statistics/sales/point.vue') },

    { path: '/kid/statistics/counsel/counsel', name: 'statistics-counsel-counsel', component: () => import('../views/statistics/counsel/counsel.vue') },

    { path: '/kid/statistics/goods/goods', name: 'statistics-goods-goods', component: () => import('../views/statistics/goods/goods.vue') },
    { path: '/kid/statistics/goods/partners', name: 'statistics-goods-partners', component: () => import('../views/statistics/goods/partners.vue') },

    { path: '/kid/statistics/settlement/settlement', name: 'statistics-settlement-settlement', component: () => import('../views/statistics/settlement/settlement.vue') },


    { path: '/kid/statistics/calculate/settlement', name: 'statistics-calculate-settlement', component: () => import('../views/statistics/calculate/settlement.vue') },
    { path: '/kid/statistics/calculate/payout', name: 'statistics-calculate-payout', component: () => import('../views/statistics/calculate/payout.vue') },
    { path: '/kid/statistics/calculate/settlementDetails', name: 'statistics-settlement-details', component: () => import('../views/statistics/calculate/settlementDetails.vue') },
    { path: '/kid/statistics/calculate/feeDetails', name: 'statistics-fee-details', component: () => import('../views/statistics/calculate/feeDetails.vue') },
]