<template>
   <Layout>
      <router-view />
    </Layout>
</template>


<script>
import Layout from '@/layout/Layout';

export default {
  components: {
    Layout 
  },
}
</script>
