import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import { ElMessageBox, ElMessage  } from 'element-plus'
import ko from 'element-plus/es/locale/lang/ko'
import 'element-plus/dist/index.css'

import ComUtil from "./utils/ComUtil";
import OpenApi from "./utils/OpenApi";
import SiteUtil from "./utils/SiteUtil";

import './assets/css/global.css'
import './assets/css/trgt.css'
import 'tui-grid/dist/tui-grid.css';

import mitt from 'mitt'
import axios from "axios"
import Navigation from "@/components/Navigation"
import * as ElIconModules from "@element-plus/icons-vue"
// import { Search, Edit, Check, Message, Star, Delete, Upload } from '@element-plus/icons'


const app = createApp(App);
app.use(store);
app.use(router);
// app.use(ElementPlus, { size: 'small', zIndex: 3000, locale: ko });
app.use(ElementPlus, { zIndex: 3000, locale: ko });
app.mount('#app');

const emitter = mitt();
app.config.globalProperties.$store = store;
app.config.globalProperties.$router = router;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$eventBus = emitter;
app.config.globalProperties.$messageBox = ElMessageBox;
app.config.globalProperties.$message = ElMessage;
app.config.globalProperties.$comUtil = ComUtil;
app.config.globalProperties.$openApi = OpenApi;
app.config.globalProperties.$siteUtil = SiteUtil;

// Global component
// Navigation
app.component('navigation', Navigation);

// Element  UI icon
for( let iconName in ElIconModules ) {
  app.component("el-icon" + iconName.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase()), ElIconModules[iconName]);
}

//check for Navigation Timing API support
// if (window.performance) {
// 	console.info("window.performance works fine on this browser");
//   }
//   console.info(performance.navigation.type);
//   if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
// 	console.info( "This page is reloaded" );
//   } else {
// 	console.info( "This page is not reloaded");
//   }

//전역 가드, 로그인 페이지 설정
router.beforeEach(function (to, from, next) {
  // 권한 X
  if (to.matched.some((routeInfo) => routeInfo.meta.authRequired == false)) {
    next();
    //  권한 O && 로그인 O, expire 체크
  } else if (
    !to.matched.some((routeInfo) => routeInfo.meta.authRequired == false) &&
    Number(store.state.userInfo.expire) > Number(ComUtil.getDate("yyyyMMddHHmmss", new Date()))
     &&  (store.state.loginExprTime == store.state.remainLoginTime  ||  (to.path != "/kid/login" && to.path != "/kid" && from.name != null ) || performance.navigation.type == performance.navigation.TYPE_RELOAD)
  ) {
    // console.log("menuId", store.state.menuId);
    // console.log(store.state.loginExprTime);
    // console.log(store.state.remainLoginTime);
    axios.defaults.headers.common["menuId"] = store.state.menuId;
    next();
  } else {
    // if( from.path != "/login" )
    router.push({ name: "login", params: { toPath: to.fullPath } });
  }

  //파트너스일 때만 system 제한
  if(store.state.userInfo.userInfo.typeCode != '100001') {
    let roleResult = false;

    //로그인도 아니고, 403도 아니라면
    if(to.path != '/kid/login'  && to.path != '/kid/403' ) {
      if( store.state.menuList[0].href.path == to.path) {
        roleResult = true;
      }
      store.state.menuList[1].child.forEach(e => {
        e.child.forEach(t => {
          if(to.path.indexOf(t.href.path) >= 0) {
            roleResult = true;
          }
        });
      });
      // console.log("roleResult", roleResult)
      if(roleResult == false) {
        router.push({path : '/kid/403'})
      }
    }
  }
});



// axios 토큰 인증에서 체크 && 토큰 재발급 interceptors
axios.interceptors.response.use((response) => {
    axios.defaults.headers.common['memberId'] = store.state.userInfo.userInfo == undefined ?  "" :  store.state.userInfo.userInfo.memberId;
    return response
  }, async function (error) {
     const originalRequest = error.config;
  
    //401처럼 인증되지 않은 사용자,  error.config._retry 연속 호출 방지
    if (error.response.status === 403 ) {
      router.push({path : '/kid/403'})
    }
  
    if (error.response.status === 401  && !originalRequest._retry ) {
      originalRequest._retry = true
  

      // Access Token 만료시 Refresh Token 으로 재발급
      await axios.get("/api/createAccessToken",{
        headers : {"Authorization" : store.state.userInfo.refreshToken}
      // Access Token, Refresh Token 재 설정
      }).then((res) => {
          // console.log(res.data)
          if(res.data.accessToken != null) {
            originalRequest.headers['Authorization'] = res.data.accessToken
            axios.defaults.headers.common['Authorization'] = res.data.accessToken
            store.commit('setRefreshToken', res.data.refreshToken)
          } else {
            //로그아웃
            store.state.userInfo = {}
            router.push({name : 'login'})
          }
      }).catch((err) => {
        console.log(err);
        router.push({name : 'login'})
      }); 
  
  
      // let userInfo = sessionObj ? JSON.parse(sessionObj) : null;
      // const access_token = await refreshAccessToken(userInfo.refreshToken);
      // if(userInfo){
      //   originalRequest.headers['Authorization'] = 'Bearer ' + access_token;
      //   userInfo.accessToken = access_token;
      //   window.sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      // }
  
       // 재전송
      return axios(originalRequest);
    }
     return Promise.reject(error);
  });