import { createRouter, createWebHistory } from 'vue-router'
import SystemRouter from './SystemRouter'
import BoardRouter from './BoardRouter'
import CounselRouter from './CounselRouter'
import MemberRouter from './MemberRouter'
import GoodsRouter from './GoodsRouter'
import GoodsOrderRouter from './GoodsOrderRouter'
import MarketingRouter from './MarketingRouter'
import SiteRouter from './SiteRouter'
import RecommandRouter from './RecommandRouter'
import SalesStatisticsRouter from './SalesStatisticsRouter'
import CalculateRouter from './CalculateRouter'
import StoreManageRouter from './StoreManageRouter'
import StoreBoardRouter from './StoreBoardRouter'
import PartnersRouter from './PartnersRouter'
import BToBRouter from './BToBRouter'


const routes = [
  {path: '/kid/', name: 'home', component: () => import('../views/Home.vue') } ,
  {path: '/kid/404', name: '404', component: () => import('../views/404.vue'),  meta: {authRequired: false, layout: 'LayoutNone'} },
  {path: '/kid/403', name: '403', component: () => import('../views/403.vue'),  meta: {authRequired: false, layout: 'LayoutNone'} },
  {path: '/kid/login', name: 'login', component: () => import('../views/Login.vue'),  meta: {authRequired: false, layout: 'LayoutNone'} },
  {path: '/kid/:pathMatch(.*)*', redirect: "/kid/404"},

  ...SystemRouter,
  ...BoardRouter,
  ...MemberRouter,
  ...CounselRouter,
  ...GoodsRouter,
  ...GoodsOrderRouter,
  ...MarketingRouter,
  ...SiteRouter,
  ...RecommandRouter,
  ...SalesStatisticsRouter,
  ...CalculateRouter,
  ...StoreManageRouter,
  ...StoreBoardRouter,
  ...PartnersRouter,
  ...BToBRouter,
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
