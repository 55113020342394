export default [
    {
        path: '/kid/member', name: 'member', component: () => import('../views/member/Member.vue'),
        children: [
            { path: '', name: 'member-list', component: () => import('../views/member/MemberList.vue') },
        ]
    },
    {
        path: '/kid/member', name: 'member2', component: () => import('../views/member/Member.vue'),
        children: [
            { path: 'dormancy', name: 'member-dormancy-list', component: () => import('../views/member/MemberDormancyList.vue')},
            { path: 'membershipManage', name: 'membership-manage-list', component: () => import('../views/member/MembershipManageList.vue')},
            { path: 'withdrawal', name: 'member-withdrawal-list', component: () => import('../views/member/MemberWithdrawalList.vue')},
            { path: '/member/:id', name: 'member-view', component: () => import('../views/member/MemberForm.vue') },
            { path: 'client/:memberId', name: 'client-view', component: () => import('../views/member/client/Client.vue'), props: true },
            { path: 'b2b/:memberId', name: 'b2b-view', component: () => import('../views/member/b2b/b2b.vue'), props: true },
            { path: 'professional/:memberId', name: 'professional-view', component: () => import('../views/member/professional/Professional.vue'), props: true },
            { path: 'professional/change', name: 'professional-change-list', component: () => import('../views/member/professional/ChangeList.vue'), props: true },
            { path: 'professional/cancel', name: 'professional-cancel-list', component: () => import('../views/member/professional/CancelList.vue'), props: true },
            { path: 'partners/:memberId', name: 'partners-view', component: () => import('../views/member/partners/Partners.vue'), props: true },
            { path: 'partners/approve', name: 'partners-approve', component: () => import('../views/member/partners/Approve.vue'), props: true },
            { path: 'partners/cancel', name: 'partners-cancel', component: () => import('../views/member/partners/Cancel.vue'), props: true },
            { path: 'partners/store/approve', name: 'partners-store-approve', component: () => import('../views/member/partners/store/Approve.vue'), props: true },
            { path: 'partners/store/cancel', name: 'partners-store-cancel', component: () => import('../views/member/partners/store/Cancel.vue'), props: true },
            { path: 'b2b/approve', name: 'b2b-approve', component: () => import('../views/member/b2b/Approve.vue'), props: true },
            { path: 'b2b/cancel', name: 'b2b-cancel', component: () => import('../views/member/b2b/Cancel.vue'), props: true },
        ]
}
]
