export default [
    {
        path: '/kid/counsel', name: 'counsel', component: () => import('../views/counsel/Counsel.vue'),
        children: [
            { path: '', name: 'counsel-list', component: () => import('../views/counsel/CounselList.vue') },
        ]
    },
    {
        path: '/kid/counsel', name: 'counsel2', component: () => import('../views/counsel/Counsel.vue'),
        children: [
            { path: 'black', name: 'counsel-black-list', component: () => import('../views/counsel/CounselBlackList.vue') },
            { path: '/counsel/:id', name: 'counsel-view', component: () => import('../views/counsel/CounselForm.vue') },
        ]
    }
]
